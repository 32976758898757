import React from 'react';
import './lokacija.styles.css';
import GoogleMapReact from 'google-map-react';
import { ImLocation } from 'react-icons/im';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Lokacija = () => {
  return (
    // Important! Always set the container height explicitly
    <div className="lokacija" style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={{
          lat: 42.77301369047519,
          lng: 19.94348691803494,
        }}
        defaultZoom={11}
      >
        <AnyReactComponent
          lat={42.77301369047519}
          lng={19.94348691803494}
          text={<ImLocation style={{ fontSize: 'xx-large', color: 'red' }} />}
        />
      </GoogleMapReact>
    </div>
  );
};

export default Lokacija;
