import React from "react";
import "./bookingButton.styles.css";

const BookingButton = (props) => {
  if (props.smjestaj || props.kontakt || props.restoran) {
    return (
      <button
        onClick={() =>
          window.open(
            "https://app.otasync.me/engine/me/?property_code=NzgzNA&currency=EUR&adults=2&children=0",
            "_blank"
          )
        }
        className={
          props.kontakt
            ? "kontakt-dugme"
            : props.restoran
            ? "restoran-dugme"
            : "smjestaj-dugme"
        }
      >
        {props.children}
      </button>
    );
  }

  return (
    <button
      className={
        props.kontakt
          ? "kontakt-dugme"
          : props.open
          ? "book2"
          : props.restoran
          ? "restoran-dugme"
          : "book"
      }
      onClick={(e) => {
        e.preventDefault();
        window.open(
          "https://app.otasync.me/engine/me/?property_code=NzgzNA&currency=EUR&adults=2&children=0",
          "_blank"
        );
      }}
    >
      {props.children}
    </button>
  );
};

export default BookingButton;
