import React from "react";
import { connect } from "react-redux";
import "./restoran.styles.css";

import restoran1 from "../../assets/slike za restoran/restoran1.jpg";
import restoran2 from "../../assets/slike za restoran/restoran2.jpg";
import restoran3 from "../../assets/slike za restoran/restoran3.jpg";
import restoran4 from "../../assets/slike za restoran/restoran4.jpg";
import restoran5 from "../../assets/slike za restoran/restoran5.jpg";
import restoran6 from "../../assets/slike za restoran/restoran6.jpg";

import PocetnaSlika from "../../components/Pocetna slika/pocetnaSlika";
import img from "../../fotografije/restoran.jpg";
import Naslov from "../../components/Naslov/naslov.component";
import Tekst from "../../components/Tekst/tekst.component";
import Carousel from "../../components/karusel/Carousel.component";
import FormaRestoran from "../../components/forme/forma-restoran/forma-restoran.component";

const Restoran = ({ jezik, setLoading }) => {
  const slike = [
    {
      imageURL: restoran1,
      number: 1,
    },
    {
      imageURL: restoran2,
      number: 2,
    },
    {
      imageURL: restoran3,
      number: 3,
    },
    {
      imageURL: restoran4,
      number: 4,
    },
    {
      imageURL: restoran5,
      number: 5,
    },
    {
      imageURL: restoran6,
      number: 6,
    },
  ];

  return (
    <div style={{ paddingBottom: "100px" }}>
      <PocetnaSlika
        slika={img}
        naslov={jezik === "srpski" ? "Ресторан" : "Restaurant"}
        setLoading={setLoading}
      />
      <Naslov>
        {jezik === "srpski" ? "Укус традиције" : "A taste of tradition"}
      </Naslov>
      <Tekst>
        {jezik === "srpski"
          ? "Прилику да уживате у националној кухињи имаћете у нашем ресторану."
          : "You will have the opportunity to enjoy the national cuisine in our restaurant."}
        <br />
        {jezik === "srpski"
          ? "Храна коју сервирамо нашим гостима је органског поријекла, већином добијена, управо, са  наше фарме."
          : `The food we serve to our guests is of organic origin, mostly obtained from our farm.`}
      </Tekst>
      <div className="karusel-restoran">
        <Carousel
          strelice={true}
          slike={slike}
          animation={"slide"}
          className={"restoran"}
        />
      </div>
     {/*  <Naslov>
        {jezik === "srpski" ? "Резервишите" : "Make a reservation"}
      </Naslov>
      <Tekst>
        {jezik === "srpski"
          ? "Ресторан функционише на јединствен начин, без постојања менија."
          : "The restaurant works in a unique way, without a menu."}
        <br />
        {jezik === "srpski"
          ? "Како бисте уживали у храни коју ћемо Вам припремити, потребно је да остварите резервацију на број телефона или на форму испод:"
          : "In order to enjoy the food we will prepare for you, you need to make a reservation calling our phone number or at the form below:"}
      </Tekst>
      <FormaRestoran /> */}
    </div>
  );
};
const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(Restoran);
