import React from "react";
import { connect } from "react-redux";
import "./dugmeRezervacija.styles.css";

const DugmeRezervacija = () => {
  return (
    <div className="wrap">
      <button
        onClick={() =>
          window.open(
            "https://app.otasync.me/engine/me/?property_code=NzgzNA&currency=EUR&adults=2&children=0",
            "_blank"
          )
        }
        className="button"
      >
        Book Now
      </button>
    </div>
  );
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(DugmeRezervacija);
