import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import styled from "styled-components";
import Jezik from "../Jezik/jezik.component";
import BookingButton from "../Book dugme/bookingButton.component";
import { ImMobile } from "react-icons/im";
import { FiMail } from "react-icons/fi";
import Facebook from "../Facebook/Facebook";
import Instagram from "../Instagram/Instagram";

const Ul = styled.ul`

  
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin-left: -10%;
  box-shadow: ${({ open }) => (open ? "0 0 0 1600px rgba(0,0,0,0.65)" : "")};

  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }


  
  
  .kontakt{
    margin-top: 100%;
    text-align: center;
  }

  .mreze{
    margin-bottom: 5%;
    margin-right: 20%;
  }

  .jezikDugme{ 
    margin-left: 2%;
    margin-top: 10%;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15%;
  }

  li {
    padding: 18px 20px;
    font-size: large;
    font-weight: 500;
  }



  a{
      color: #2F2F2F;
      border-bottom: 2px solid;
      padding-bottom: 8px;
      border-bottom-color: transparent;
      transition: all ease-in-out 0.3s;
  }

  a:hover{
    cursor: pointer;
    border-bottom-color: green;
  }

  Jezik{
    display: none;
  }

  @media (max-width: 1400px) {
    margin-left: -20%;
    li {
      padding: 18px 15px;
    }
  }

  @media (max-width: 1200px) {
    overflow-y: scroll;
    position: fixed;
    z-index: 5;
    margin-right: 0;
    flex-flow: column nowrap;
    background-color: ${({ open }) => (open ? "white" : "#181818")};
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: background-color 0.05s ease-in-out;
    transition: transform 0.3s ease-in-out;
    margin-left: 10%
    text-decoration: underline;
    li {
        text-align: left;
        margin-left: 10%;
        font-size: 130%;
        
    }
    .naslovna{
      padding-right: 66%;
    }
    .restoran{
      padding-right: 67%;
    }
    .o-nama{
      padding-right: 69%;
    }
    .organizacija{
      padding-right: 31%;
    }
    .kontakt{
      text-decoration: none;
      border: none;
      transition: color 0.3s ease-in-out;
    }

    .smjestaj{
      padding-right: 67%;
    }
    .link{
      text-decoration: none;
      border: none;
      transition: color 0.3s ease-in-out;
    }
    .pomocniBorder{
      margin-top: -5%;
      border-bottom:1px solid #ccc;
      margin-left: 16.5%;
      margin-right: 15.4%;
      margin-bottom: 5%;
    }

    .link:hover{
      color: green;
    }

    .kontakt:hover{
      color: green;
    }
   button.book2::after{
     display: none;
   }
   button.book2::before{
    display: none;
  }
  }

  @media (max-height: 820px){
    .mreze{
      margin-top: -75%;
    }
  }

`;

const DesnoZaglavlje = ({ open, jezik }) => {
  let history = useHistory();

  if (!open) {
    return (
      <Ul open={open}>
        <li>
          {" "}
          <a href={() => false} onClick={() => history.push("/")}>
            {jezik === "srpski" ? "Насловна" : "Home"}
          </a>
        </li>
        <li>
          <a href={() => false} onClick={() => history.push("/o-nama")}>
            {jezik === "srpski" ? "О нама" : "About us"}
          </a>
        </li>
        <li>
          <a href={() => false} onClick={() => history.push("/smjestaj")}>
            {jezik === "srpski" ? "Смјештај" : "Accommodation"}
          </a>
        </li>
        <li>
          <a href={() => false} onClick={() => history.push("/restoran")}>
            {jezik === "srpski" ? "Ресторан" : "Restaurant"}
          </a>
        </li>
        <li>
          <a
            href={() => false}
            onClick={() => history.push("/organizuj-dogadjaj")}
          >
            {jezik === "srpski" ? "Организација догађаја" : "Event organizing"}
          </a>
        </li>
        <li>
          <a href={() => false} onClick={() => history.push("/kontakt")}>
            {jezik === "srpski" ? "Контакт" : "Contact"}
          </a>
        </li>
      </Ul>
    );
  } else {
    return (
      <Ul open={open}>
        <div className="jezikDugme">
          <BookingButton open={open}>
            Book Now
          </BookingButton>  
          <Jezik />
        </div>
        <li>
          {" "}
          <a
            href={() => false}
            onClick={() => history.push("/")}
            className="naslovna link"
          >
            {jezik === "srpski" ? "Насловна" : "Home"}
          </a>
        </li>
        <div className="pomocniBorder"></div>
        <li>
          <a
            href={() => false}
            onClick={() => history.push("/o-nama")}
            className="o-nama link"
          >
            {jezik === "srpski" ? "О нама" : "About us"}
          </a>
        </li>
        <div className="pomocniBorder"></div>
        <li>
          <a
            href={() => false}
            onClick={() => history.push("/smjestaj")}
            className="smjestaj link"
          >
            {jezik === "srpski" ? "Смјештај" : "Accommodation"}
          </a>
        </li>
        <div className="pomocniBorder"></div>
        <li>
          <a
            href={() => false}
            onClick={() => history.push("/restoran")}
            className="restoran link"
          >
            {jezik === "srpski" ? "Ресторан" : "Restaurant"}
          </a>
        </li>
        <div className="pomocniBorder"></div>
        <li>
          <a
            href={() => false}
            onClick={() => history.push("/organizuj-dogadjaj")}
            className="organizacija link"
          >
            {jezik === "srpski" ? "Организација догађаја" : "Event organizing"}
          </a>
        </li>
        <div className="pomocniBorder"></div>
        <li>
          <a
            href={() => false}
            onClick={() => history.push("/kontakt")}
            className="kontakt"
          >
            {jezik === "srpski" ? "Контакт" : "Contact"}
          </a>
        </li>
        <div className="pomocniBorder"></div>
        <div className="kontakt">
          <div className="mreze">
            <Instagram />
            <Facebook />
          </div>
          <h4>
            {" "}
            <ImMobile className="tel" /> +382 (0) 69 047 834{" "}
          </h4>
          <h4>
            {" "}
            <FiMail className="mail" /> lucplus@t-com.me
          </h4>
        </div>
      </Ul>
    );
  }
};

const mapStateToProps = ({ jezik }) => ({
  jezik: jezik.jezik,
});

export default connect(mapStateToProps)(DesnoZaglavlje);
